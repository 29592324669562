/* datepicker-custom.css */
.react-datepicker {
    background-color: #1E2132;
    border: 1px solid #2D3748;
  }
  
  .react-datepicker__header {
    background-color: #2D3748;
    border-bottom: 1px solid #4A5568;
  }
  
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: white;
  }
  
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background-color: #4A5568;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #4299E1;
  }
  
  .react-datepicker__triangle {
    border-bottom-color: #2D3748 !important;
  }

  .fixed-bottom {
    bottom: 20px; /* Adjust if necessary */
    z-index: 10;
    padding: 16px 32px; /* Increase padding for a larger button */
    font-size: 18px; /* Increase font size */
  }
  
  .load-more-link {
    margin-bottom: 60px; /* Adjust to give space for the button */
  }